import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

import styles from "./Answer.module.css";
import { ChatAppResponse, getCitationFilePath, SpeechConfig } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";
import { SpeechOutputBrowser } from "./SpeechOutputBrowser";
import { SpeechOutputAzure } from "./SpeechOutputAzure";

interface Props {
    answer: ChatAppResponse;
    index: number;
    speechConfig: SpeechConfig;
    isSelected?: boolean;
    isStreaming: boolean;
    onCitationClicked: (filePath: string) => void;
    // onThoughtProcessClicked: () => void;
    // onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    showSpeechOutputBrowser?: boolean;
    showSpeechOutputAzure?: boolean;
}

export const Answer = ({ answer, ...props }: Props) => {
    const followupQuestions = answer.context?.followup_questions;
    const messageContent = answer.message.content;
    const isContentSafety = messageContent.includes("falls outside our acceptable usage guidelines");
    
    const parsedAnswer = useMemo(() => parseAnswerToHtml(messageContent, props.isStreaming, props.onCitationClicked), [answer]);
    const { t } = useTranslation();
    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    return (
        <Stack className={`${styles.answerContainer} ${props.isSelected && styles.selected} ${isContentSafety ? styles.contentSafety : ''}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        {/* <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title={t("tooltips.showThoughtProcess")}
                            ariaLabel={t("tooltips.showThoughtProcess")}
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.context.thoughts?.length}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title={t("tooltips.showSupportingContent")}
                            ariaLabel={t("tooltips.showSupportingContent")}
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.context.data_points}
                        /> */}
                        {props.showSpeechOutputAzure && (
                            <SpeechOutputAzure answer={sanitizedAnswerHtml} index={props.index} speechConfig={props.speechConfig} isStreaming={props.isStreaming} />
                        )}
                        {props.showSpeechOutputBrowser && <SpeechOutputBrowser answer={sanitizedAnswerHtml} />}
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={`${styles.answerText} ${isContentSafety ? styles.contentSafetyText : ''}`}>
                    <ReactMarkdown children={sanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} />
                </div>
            </Stack.Item>

            {!isContentSafety && (
                <>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item>
                            <span className={styles.citationLearnMore}>{t("citationWithColon")}</span>
                            <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                {parsedAnswer.citations.map((x, i) => {
                                    const path = getCitationFilePath(x);
                                    return (
                                        <a key={i} className={styles.citation} title={x} onClick={() => props.onCitationClicked(path)}>
                                            {`${++i}. ${x}`}
                                        </a>
                                    );
                                })}
                            </Stack>
                        </Stack.Item>
                    )}
                    <br />

                    {!!followupQuestions?.length && props.showFollowupQuestions && props.onFollowupQuestionClicked && (
                        <Stack.Item>
                            <span className={styles.followupQuestionLearnMore}>{t("followupQuestions")}</span>
                            <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                                {followupQuestions.map((x, i) => {
                                    return (
                                        <a key={i} className={styles.followupQuestion} title={x} onClick={() => props.onFollowupQuestionClicked?.(x)}>
                                            {`${x}`}
                                        </a>
                                    );
                                })}
                            </Stack>
                        </Stack.Item>
                    )}
                </>
            )}
        </Stack>
    );
};
